import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import addStylesToContent from "../addStylesToContent";
import CookieConsentContext from "../contexts/CookieConsentContext";
import Button from "../components/UI/Button";

const UseDefaultsButton = () => {
	const [show, setShow] = React.useState(true);
	const consentContext = React.useContext(CookieConsentContext);

	if (show) {
		return (
			<Button
				primary
				onClick={() => {
					consentContext.setGaEnabled(true);
					consentContext.saveConsent();
					setShow(false);
				}}
			>
				Use recommended settings
			</Button>
		);
	}

	return (
		<span className="inline-block px-4 py-2 border-l-2 bg-gray-100 border-teal-700 text-teal-700 dark:bg-gray-700 dark:border-teal-600 dark:text-teal-300">
			Got it. We'll use recommended settings from now on.
		</span>
	);
};

const CookiePage = () => {
	const consentContext = React.useContext(CookieConsentContext);

	React.useEffect(() => {
		consentContext.saveConsent();
	}, [consentContext]);

	return (
		<Layout>
			<SEO title="How this site uses cookies" />
			<div className="container mx-auto px-6">
				<article>
					<h1 className="text-4xl font-semibold mt-4">
						How this site uses cookies
					</h1>
					{addStylesToContent(
						<>
							<p>
								This page explains how this site uses cookies
								and related technologies.
							</p>
							<p>
								This site uses both cookies and HTML5
								localStorage to store information in your
								browser.
							</p>
							<p>
								You can read on and customise these settings as
								you like, or you can just use the recommended
								settings.
							</p>
							<p>
								<UseDefaultsButton />
							</p>
							<p>
								Any changes you make on this page will be saved
								automatically.
							</p>

							<h2>Site functionality</h2>
							<p>
								Both and cookies and localStorage are used to
								enable basic site functionality, and can be
								switched off by disabling them with your browser
								settings.
							</p>
							<p>
								I don't recommend doing this as the site might
								break.
							</p>
							<h3>Light and dark modes</h3>
							<p>
								This site can be read in either light or dark
								mode. This is an entirely visual change and
								doesn't alter any functionality or content.
							</p>
							<p>
								If you turn on dark mode, the site will set a
								localStorage entry called "preferDarkMode" with
								a value of "true". If you switch back to light
								mode, it will set it to "false".
							</p>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<th>Values</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>preferDarkMode</td>
										<td>"true" or "false"</td>
									</tr>
								</tbody>
							</table>
							<h3>Cookie banner</h3>
							<p>
								When you first visit the site, you'll get a
								message at the top warning you that the site
								uses cookies.
							</p>
							<p>
								It will continue to appear until you dismiss it,
								or you open this page. Doing either will set a
								localStorage entry called "consent:saved" to
								"true".
							</p>
							<p>
								That indicates that you've either dismissed the
								banner, or read this page and set any cookie or
								analytics related preferences.
							</p>
							<p>
								If you click "accept all", or enable any
								optional settings on this page, then the site
								will set a localStorage entry to record that.
								For example, accepting google analytics will set
								a local storage entry called "consent:GA" to
								indicate that you've accepted google analytics.
							</p>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<th>Values</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>consent:saved</td>
										<td>"true" or "false"</td>
									</tr>
									<tr>
										<td>consent:GA</td>
										<td>"true" or "false"</td>
									</tr>
								</tbody>
							</table>
							<h3>Article last read, and article progress</h3>
							<p>
								It's helpful to see which articles you have or
								haven't read already.
							</p>
							<p>
								Sometimes the site will visually indicate that
								to you, such as when you see an article in
								search results, or on certain types of links.
								HTML5 localStorage is used to facilitate this.
							</p>
							<p>
								When you open an article, the site will create a
								localStorage entry called
								"articleLastRead:the-article-name" and set it to
								the current date, and another called
								"articleProgress:the-article-name", and set it
								to zero.
							</p>
							<p>
								As you read the article, it will update the
								article progress entry with how far you've read
								expressed as a percentage. So if you've read
								halfway, it will be set to 50.
							</p>
							<p>
								We don't collect this data. It stays on your
								local browser and is only used to visually
								indicate to you when you've already read an
								article.
							</p>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<th>Values</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											articleLastRead:the-article-name
										</td>
										<td>
											Date in Javascript ISO format, e.g.
											2021-10-22T07:00:54.463Z
										</td>
									</tr>
									<tr>
										<td>
											articleProgress:the-article-name
										</td>
										<td>
											Percentage indicating how much
											you've scrolled down the page
										</td>
									</tr>
								</tbody>
							</table>
							<h2>Feedback</h2>
							<p>
								At the bottom of each article are two buttons
								which let you provide feedback on whether the
								article helped you or not.
							</p>
							<p>
								Clicking either button will send information
								indicating your thoughts. This information is
								collated using Google Analytics. However, no
								extra information is captured, unless you've
								enabled the Analytics option below.
							</p>
							<p>
								We also create a localStorage entry on your
								browser to record that you've already sent
								feedback on that article. This is used to
								prevent asking you for feedback on that same
								article again.
							</p>
							<table>
								<thead>
									<tr>
										<th>Name</th>
										<th>Values</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											articleFeedback:the-article-name
										</td>
										<td>true</td>
									</tr>
								</tbody>
							</table>
							<h2>Analytics</h2>
							<p>
								This site uses google analytics to help me
								understand which articles are useful, and which
								aren't.
							</p>
							<p>
								Because it will be periodically updated, I can't
								always know exactly which cookies it sets in
								your browser. However, google publish their
								cookie usage policy here:
							</p>
							<p>
								<a
									href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"
									target="_blank"
									rel="noreferrer"
								>
									https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
								</a>
							</p>
							<p>
								You can choose whether or not to enable google
								analytics. I'd prefer it if you did, because the
								feedback it provides helps me write more useful
								articles for you.
							</p>
							<p>
								<GaConsentButton />
							</p>
						</>
					)}
				</article>
			</div>
		</Layout>
	);
};
const GaConsentButton = () => {
	const consentContext = React.useContext(CookieConsentContext);
	return (
		<Button
			primary
			id="use-ga-button"
			onClick={() => {
				consentContext.setGaEnabled(!consentContext.gaEnabled);
			}}
		>
			{consentContext.gaEnabled
				? "Turn off google analytics"
				: "Turn on google analytics"}
		</Button>
	);
};

export default CookiePage;
