import React from "react";
import classNames from "classnames";

const Button = ({ primary = false, className, children, ...otherProps }) => (
	<button
		className={classNames({
			"bg-teal-500 text-white rounded px-4 py-2": primary,
			"todo-non-primary-button-settings": !primary,
			className: true,
		})}
		{...otherProps}
	>
		{children}
	</button>
);

export default Button;
